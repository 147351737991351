.navbar {
    background-color: black;
}

.brand-and-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-link {
    color: gold !important;
    font-size: 1.25rem !important;
    font-weight: 600 !important;
}

.nav-link:hover {
    color: rgb(247, 228, 188) !important;
    transform: translateY(-5px);
    
}

.nav-link.active {
    color: white !important;
}

.navbar-toggler {
    border-color: gold !important;
}

.logo {
    max-height: 150px;
    margin: 0 auto;
}

.navbar-toggler {
    border-color: gold !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='gold' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.motto {
    /* Add styles for the motto text */
    color: gold;
    font-size: 1.25rem;
}