.services-container {
    padding: 20px;
    background-image: url('../../images/whiteBackground.webp');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
}

@keyframes gradientAnimation {
    0%, 100% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
}

.flash-title {
    font-size: 3rem !important;
    text-align: center;
    margin-bottom: 30px;
    background: linear-gradient(45deg, rgb(172, 149, 20), gold, rgb(172, 149, 20));
    background-size: 200% 100%;
    -webkit-background-clip: text;
    color: transparent;
    animation: gradientAnimation 10s infinite;
}

.flash-title-dark {
    font-size: 3rem !important;
    text-align: center;
    margin-bottom: 30px;
    background: linear-gradient(45deg, black, gold, black);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    color: transparent;
    animation: gradientAnimation 10s infinite;
}

/* Dark mode styles for h2 */
@media (prefers-color-scheme: dark) {
    .flash-title, .flash-title-dark {
        animation: none;
        color:rgb(138, 117, 0) !important;
    }
}

/* Remove animation for screens smaller than 650px */
@media (max-width: 650px) {
    .flash-title, .flash-title-dark {
        animation: none;
        color:rgb(138, 117, 0) !important;
    }
}


.section-title {
    color: #000;
    font-size: 2rem;
}

.feature-card {
    margin: 20px 0;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.list-center {
    text-align: left;
    margin: 0 auto;
}

.service-flexibility {
    color: #000;  
    font-size: 1.2em;  
    text-align: center;  
    margin-top: 20px;  
    padding: 10px;  
    background-color: #f9f9f9;
    border-radius: 5px; 
}
