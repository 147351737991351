/* Modal Base Styles */
.modal-container {
    background-color: black;
    color: white;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    text-align: center;
  }
  
  /* Modal Header */
  .modal-header {
    font-size: 24px;
    background-color: #FFD700; /* More vivid gold */
    text-shadow:  px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
  
  /* Modal Content */
  .modal-content {
    font-size: 18px;
    margin: 10px 0;
  }
  
  /* Modal Footer */
  .modal-footer {
    margin-top: 20px;
  }
  
  /* Close Button */
  .close-button {
    color: gold;
    font-size: 20px;
    cursor: pointer;
    float: right;
  }
  
  /* Email Input */
  .email-input {
    padding: 10px;
    border: 1px solid gold;
    border-radius: 5px;
    width: 100%;
  }
  
  /* Subscribe Button */
  .subscribe-button {
    padding: 10px 20px;
    background-color: gold;
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
  }
  
  .subscribe-button:hover {
    background-color: darkgoldenrod;
  }
  
  /* Media Queries */
  @media (max-width: 991px) {
    .modal-container {
      width: 90%;
    }
  }