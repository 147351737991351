.sticky-button {
    position: fixed;
    right: 0px;
    top: 90%;
    transform: translateY(-50%);
    z-index: 100; /* To make sure the button stays on top */
    width: 80px;
    height: 100px;
    
  }

  .sticky-button button {
    width: 100%;
    height: 100%;
    color: #000;
    background-color: gold;
    font-weight: 700;
    text-align: center;
    border-radius: 50px;
  }

  .sticky-button button:hover {
    width: 110%;
    height: 110%;
    color: gold;
    background-color: #000;
    font-weight: 800;
  }

  .sticky-button button:active {
    width: 110%;
    height: 110%;
    color: gold;
    background-color: white;
    font-weight: 800;
  }