/* Projects Section */
.ProjectsContainer {
    display: flex;
    flex-direction: column;
    min-height: 110vh;
  }
  
  .ProjectsTitle {
    text-align: center;
    font-size: 3rem;
    color: rgb(253, 117, 53);
  }
  
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 32rem;
  }
  
  .card {
    cursor: pointer;
    background-color: transparent;
    height: 40vh;
    perspective: 1000px;
  }
  
  .cardP {
    font-size: .85rem;
  }
  
  .card h1 {
    font-size: 25px;
    border-bottom: 1px #fff solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .card:hover .card-inner {
    transform: rotateY(180deg);
  }
  
  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .card-back {
    background-color: #333;
    color: white;
    transform: rotateY(180deg);
    padding-right: 5%;
  }
  
  .card li {
    list-style: none;
    padding-bottom: 10px;
  }
  
  @media (max-width: 800px) {
    .cards {
      grid-template-columns: repeat(2, 1fr);
      display: grid;
      gap: 1rem;
    }
  }
  
  @media (max-width: 650px) {
    .cards {
      grid-template-columns: repeat(1, 1fr);
      display: grid;
    }
  }
  
  @media (max-width: 434px) {
    .cards {
      grid-template-columns: repeat(1, 1fr);
      display: grid;
    }
  }