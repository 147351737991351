.footer {
    color: white;
    background-color: black;
    width: 100%;
    text-align: center;
}
.gold {
    color: gold !important;
}
.gold.active {
    color: white !important;
}

.white {
    color: white;
}
  
  .footer-icon {
    font-size: 3rem;
    color: white;
    border-radius: 20%;
  }
  
  .footer-icon:hover {
    background-color: white;
    color: black;
    font-size: 3.5rem;
    transform: translateY(-5px);
  
  }
  
  .footer .footer-link {
    color: gold;
  }
  
  .footer .footer-link:hover {
    transform: translateY(-5px);
    color: gold;
  }
  
  .footer-h5 {
    padding-bottom: 10%;
  }