.home {
  font-family: Arial, Helvetica, sans-serif;
}

.home-row {
    margin-top: 20px;
    align-items: center;
}

.home-text {
    padding: 20px;
}

.home-image {
    width: 100%;
    height: auto;
}

.intro-text {
  text-align: center;
}

.cross-link {
  text-decoration: none;
  color: rgb(175, 128, 0) !important;
}

.cross-link:hover {
  text-decoration: none;
  color: rgb(46, 0, 253) !important;
}

.center-button {
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
}

.newsletter-button {
  background-color: gold !important;
  color: black !important;
  transition: transform 0.8s ease !important; /* for smooth hover effect */
  margin-bottom: 30px;
}

.newsletter-button:hover {
  transform: scale(1.1); /* Enlarge 10% */
}

.newsletter-button:active {
  background-color: black !important;
  color: gold !important;
}

.responsive-table {
  width: 100%;
  overflow-x: auto; 
  -webkit-overflow-scrolling: touch; 
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 12px; 
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); 
  overflow: hidden; 
}

.comparison-table th,
.comparison-table td {
  border: 1px solid #ccc;
  padding: 12px 15px; 
  text-align: center;
  transition: background-color 0.3s ease;
}

.comparison-table th {
  background-color: #24292e; 
  color: white;
  font-weight: 500; 
}

.comparison-table td {
  background-color: #f5f5f5; 
}

.comparison-table tbody tr:hover {
  background-color: #f3f3f3;
}

.comparison-table tr:nth-child(even) td {
  background-color: #e6e6e6; 
}

.comparison-table td:nth-child(2) {
  color: green; 
}

.comparison-table td:nth-child(3) {
  color: #d9534f; 
}

.comparison-table td:nth-child(4) {
  color: #0275d8; 
}

.green {
  color: green !important;
}

@media (max-width: 480px) {
  .comparison-table td, 
  .comparison-table th {
      font-size: 12px; /* or an appropriate size */
      padding: 8px 10px; /* reduced padding */
  }
}
