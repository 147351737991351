.motto {
    /* Add styles for the motto text */
    color: gold;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 0px;
}
.container-motto {
    background-color: black;
}