body {
    font-family: Arial, sans-serif;
}

form {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

label {
    display: block;
    margin-top: 20px;
    font-size: 1.1rem;
    font-weight: 500;
}

input[type="text"],
input[type="tel"],
input[type="url"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type="checkbox"] {
    margin-top: 10px;
    margin-left: 10px;
}

button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: gold;
    color: black;
    font-weight: 700;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #000;
    color: gold;
}

textarea,
input:not(.checkbox-size) {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.checkbox-size {
    transform: scale(2);
    
    border: 2px solid #000;
    
}

.checkbox-container {
    display: flex;
    align-items: center;
}