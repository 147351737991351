.about-container {
    padding-top: 20px;
    background-color: black;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .about-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .about-container p {
    color: white;
  }
  
  .white {
    color: white;
    -webkit-text-stroke: 1px gold;
  }
  
  .about-container .about-link {
      color: gold;
  }

  .about-link {
    margin-left: 10px;
  }

  .about-links {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-links a {
    margin: 0 15px;
    text-decoration: none;
    color: #007bff;
  }
  
  .about-links a:hover {
    text-decoration: underline;
  }

  .cross-link {
    text-decoration: none;
    color: gold;
  }

  .cross-link:hover {
    text-decoration: none;
    color: rgb(255, 243, 137);
  }

  @media (max-width: 991px) {
    .margin-bottom {
        margin-bottom: 10px;
    }
}